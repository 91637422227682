/* .table {
  display: flex;
  width: 100%;
  height: 100%;
}
.table__cell {
  position: relative;
  display: flex;
  width: 20%;
  align-items: center;
  justify-content: center;
  transition: width 500ms cubic-bezier(0.4, 0, 0.2, 1);
}
.table__cell:nth-child(1) {
  background-color: #ff8b8b;
}
.table__cell:nth-child(2) {
  background-color: #0bbcd6;
}
.table__cell:nth-child(3) {
  background-color: #b18ae0;
}
.table__cell:nth-child(4) {
  background-color: #eed974;
}
.table__cell:nth-child(5) {
  background-color: #005397;
}
.table__cell.active {
  width: 50%;
}
.table__cell span {
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0;
}
.table__cell.active span {
  opacity: 1;
  transition-delay: 300ms;
} */

@import url(https://fonts.googleapis.com/css?family=Roboto);

body {
  font-family: 'Roboto', 'Sans Serif';
  font-size: 18pt;
  color: #fff;
  background: #3498db;
}

.wrapper {
  margin: 10% auto;
  width: 400px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

label {
  display: block;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #fff;
  border-bottom: none;
}

label:hover {
  background: #26c281;
}

label.last {
  border-bottom: 1px solid #fff;
}

ul ul li {
  padding: 10px;
  background: #59abe3;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgb(211, 206, 206);
}

input[type='checkbox'] {
  position: absolute;
  left: -9999px;
}

input[type='checkbox'] ~ ul {
  height: 0;
  transform: scaleY(0);
}

input[type='checkbox']:checked ~ ul {
  height: 100%;
  transform-origin: top;
  transition: transform 0.2s ease-out;
  transform: scaleY(1);
}

input[type='checkbox']:checked + label {
  background: #26c281;
  border-bottom: 1px solid #fff;
}

[type='date'] {
  background: #fff
    url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)
    97% 50% no-repeat;
}
[type='date']::-webkit-inner-spin-button {
  display: none;
}
[type='date']::-webkit-calendar-picker-indicator {
  opacity: 0;
}

label {
  display: block;
}
input#datepicker {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #fff;
  padding: 3px 5px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
  max-width: 190px;
}

.season-dates {
  display: flex;
  justify-content: space-between;
}

.season-dates > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

.season-dates > div > p {
  font-weight: bold;
  font-size: 17px;
}

ul li > button {
  background: none;
  border: none;
  font-size: 15px;
  font-weight: bold;
  color: rgb(2, 66, 34);
  cursor: pointer;
  padding: 5px;
  margin: 5px;
}

.danger-bg {
  background: #dc3545;
}

.team-name {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 5px;
  cursor: pointer;
}
