.layout {
  /* margin: 5% auto; */
  /* width: 700px; */
}

.header {
  margin: 6% auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 38px;
  background-color: rgba(52, 152, 219, 0.9);
  z-index: 99;
}

.header_btn {
  width: 150px;
  height: 30px;
  display: flex;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #fff;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: 1.3rem;
}

.btn_active {
  background-color: #26c281;
}

.logout-btn {
  position: fixed;
  right: 15px;
  user-select: none;
}
