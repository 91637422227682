.input {
  width: 100px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label {
  font-size: 12px;
  margin-right: 10px;
  margin-left: 10px;
  border: none;
}

.margin-vertical-10 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.btn_svg {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  cursor: pointer;
}

.paragraph {
  font-size: 16px;
}

.golden {
  color: gold;
}
.red {
  color: rgb(255, 39, 39);
}
